// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-before-after-js": () => import("./../../../src/pages/before-after.js" /* webpackChunkName: "component---src-pages-before-after-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customers-ballarat-care-story-js": () => import("./../../../src/pages/customers/ballarat_care_story.js" /* webpackChunkName: "component---src-pages-customers-ballarat-care-story-js" */),
  "component---src-pages-customers-from-complex-spreadsheets-to-one-simple-time-crew-tracking-app-js": () => import("./../../../src/pages/customers/from-complex-spreadsheets-to-one-simple-time-crew-tracking-app.js" /* webpackChunkName: "component---src-pages-customers-from-complex-spreadsheets-to-one-simple-time-crew-tracking-app-js" */),
  "component---src-pages-customers-how-camelo-helps-this-nail-salon-owner-manage-her-business-and-time-js": () => import("./../../../src/pages/customers/how-camelo-helps-this-nail-salon-owner-manage-her-business-and-time.js" /* webpackChunkName: "component---src-pages-customers-how-camelo-helps-this-nail-salon-owner-manage-her-business-and-time-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-customers-intercon-management-consulting-story-js": () => import("./../../../src/pages/customers/intercon_management_consulting_story.js" /* webpackChunkName: "component---src-pages-customers-intercon-management-consulting-story-js" */),
  "component---src-pages-customers-kohvieri-coffee-shop-story-js": () => import("./../../../src/pages/customers/kohvieri_coffee_shop_story.js" /* webpackChunkName: "component---src-pages-customers-kohvieri-coffee-shop-story-js" */),
  "component---src-pages-customers-la-mex-restaurant-story-js": () => import("./../../../src/pages/customers/la-mex-restaurant-story.js" /* webpackChunkName: "component---src-pages-customers-la-mex-restaurant-story-js" */),
  "component---src-pages-customers-shifts-planning-time-clocks-for-call-center-js": () => import("./../../../src/pages/customers/shifts-planning-time-clocks-for-call-center.js" /* webpackChunkName: "component---src-pages-customers-shifts-planning-time-clocks-for-call-center-js" */),
  "component---src-pages-customers-ups-store-creekside-js": () => import("./../../../src/pages/customers/ups-store-creekside.js" /* webpackChunkName: "component---src-pages-customers-ups-store-creekside-js" */),
  "component---src-pages-customers-whittlesey-church-story-js": () => import("./../../../src/pages/customers/whittlesey_church_story.js" /* webpackChunkName: "component---src-pages-customers-whittlesey-church-story-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-scheduling-software-js": () => import("./../../../src/pages/features/scheduling-software.js" /* webpackChunkName: "component---src-pages-features-scheduling-software-js" */),
  "component---src-pages-features-time-and-attendance-js": () => import("./../../../src/pages/features/time-and-attendance.js" /* webpackChunkName: "component---src-pages-features-time-and-attendance-js" */),
  "component---src-pages-features-workplace-communication-js": () => import("./../../../src/pages/features/workplace-communication.js" /* webpackChunkName: "component---src-pages-features-workplace-communication-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-agriculture-js": () => import("./../../../src/pages/industries/agriculture.js" /* webpackChunkName: "component---src-pages-industries-agriculture-js" */),
  "component---src-pages-industries-barbershops-js": () => import("./../../../src/pages/industries/barbershops.js" /* webpackChunkName: "component---src-pages-industries-barbershops-js" */),
  "component---src-pages-industries-base-js": () => import("./../../../src/pages/industries/base.js" /* webpackChunkName: "component---src-pages-industries-base-js" */),
  "component---src-pages-industries-beauty-spas-js": () => import("./../../../src/pages/industries/beauty-spas.js" /* webpackChunkName: "component---src-pages-industries-beauty-spas-js" */),
  "component---src-pages-industries-call-centers-js": () => import("./../../../src/pages/industries/call-centers.js" /* webpackChunkName: "component---src-pages-industries-call-centers-js" */),
  "component---src-pages-industries-caregiving-js": () => import("./../../../src/pages/industries/caregiving.js" /* webpackChunkName: "component---src-pages-industries-caregiving-js" */),
  "component---src-pages-industries-charity-js": () => import("./../../../src/pages/industries/charity.js" /* webpackChunkName: "component---src-pages-industries-charity-js" */),
  "component---src-pages-industries-cleaning-services-js": () => import("./../../../src/pages/industries/cleaning-services.js" /* webpackChunkName: "component---src-pages-industries-cleaning-services-js" */),
  "component---src-pages-industries-construction-js": () => import("./../../../src/pages/industries/construction.js" /* webpackChunkName: "component---src-pages-industries-construction-js" */),
  "component---src-pages-industries-education-js": () => import("./../../../src/pages/industries/education.js" /* webpackChunkName: "component---src-pages-industries-education-js" */),
  "component---src-pages-industries-emergency-js": () => import("./../../../src/pages/industries/emergency.js" /* webpackChunkName: "component---src-pages-industries-emergency-js" */),
  "component---src-pages-industries-entertainment-js": () => import("./../../../src/pages/industries/entertainment.js" /* webpackChunkName: "component---src-pages-industries-entertainment-js" */),
  "component---src-pages-industries-healthcare-js": () => import("./../../../src/pages/industries/healthcare.js" /* webpackChunkName: "component---src-pages-industries-healthcare-js" */),
  "component---src-pages-industries-hospitality-js": () => import("./../../../src/pages/industries/hospitality.js" /* webpackChunkName: "component---src-pages-industries-hospitality-js" */),
  "component---src-pages-industries-hotels-resorts-js": () => import("./../../../src/pages/industries/hotels-resorts.js" /* webpackChunkName: "component---src-pages-industries-hotels-resorts-js" */),
  "component---src-pages-industries-manufacturing-logistics-js": () => import("./../../../src/pages/industries/manufacturing-logistics.js" /* webpackChunkName: "component---src-pages-industries-manufacturing-logistics-js" */),
  "component---src-pages-industries-nail-salons-js": () => import("./../../../src/pages/industries/nail-salons.js" /* webpackChunkName: "component---src-pages-industries-nail-salons-js" */),
  "component---src-pages-industries-nonprofits-js": () => import("./../../../src/pages/industries/nonprofits.js" /* webpackChunkName: "component---src-pages-industries-nonprofits-js" */),
  "component---src-pages-industries-professional-services-js": () => import("./../../../src/pages/industries/professional-services.js" /* webpackChunkName: "component---src-pages-industries-professional-services-js" */),
  "component---src-pages-industries-remote-work-js": () => import("./../../../src/pages/industries/remote-work.js" /* webpackChunkName: "component---src-pages-industries-remote-work-js" */),
  "component---src-pages-industries-restaurants-bars-coffee-js": () => import("./../../../src/pages/industries/restaurants-bars-coffee.js" /* webpackChunkName: "component---src-pages-industries-restaurants-bars-coffee-js" */),
  "component---src-pages-industries-retail-js": () => import("./../../../src/pages/industries/retail.js" /* webpackChunkName: "component---src-pages-industries-retail-js" */),
  "component---src-pages-industries-security-js": () => import("./../../../src/pages/industries/security.js" /* webpackChunkName: "component---src-pages-industries-security-js" */),
  "component---src-pages-industries-supermarkets-js": () => import("./../../../src/pages/industries/supermarkets.js" /* webpackChunkName: "component---src-pages-industries-supermarkets-js" */),
  "component---src-pages-industries-transportation-js": () => import("./../../../src/pages/industries/transportation.js" /* webpackChunkName: "component---src-pages-industries-transportation-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-policies-abuse-js": () => import("./../../../src/pages/policies/abuse.js" /* webpackChunkName: "component---src-pages-policies-abuse-js" */),
  "component---src-pages-policies-cancellation-js": () => import("./../../../src/pages/policies/cancellation.js" /* webpackChunkName: "component---src-pages-policies-cancellation-js" */),
  "component---src-pages-policies-data-processing-js": () => import("./../../../src/pages/policies/data-processing.js" /* webpackChunkName: "component---src-pages-policies-data-processing-js" */),
  "component---src-pages-policies-privacy-js": () => import("./../../../src/pages/policies/privacy.js" /* webpackChunkName: "component---src-pages-policies-privacy-js" */),
  "component---src-pages-policies-refund-js": () => import("./../../../src/pages/policies/refund.js" /* webpackChunkName: "component---src-pages-policies-refund-js" */),
  "component---src-pages-policies-terms-of-service-js": () => import("./../../../src/pages/policies/terms-of-service.js" /* webpackChunkName: "component---src-pages-policies-terms-of-service-js" */),
  "component---src-pages-policies-until-the-end-of-internet-js": () => import("./../../../src/pages/policies/until-the-end-of-internet.js" /* webpackChunkName: "component---src-pages-policies-until-the-end-of-internet-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-reset-pass-js": () => import("./../../../src/pages/reset-pass.js" /* webpackChunkName: "component---src-pages-reset-pass-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-done-js": () => import("./../../../src/pages/signup/done.js" /* webpackChunkName: "component---src-pages-signup-done-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-pages-signup-welcome-js": () => import("./../../../src/pages/signup/welcome.js" /* webpackChunkName: "component---src-pages-signup-welcome-js" */),
  "component---src-pages-templates-9-80-work-schedule-js": () => import("./../../../src/pages/templates/9-80-work-schedule.js" /* webpackChunkName: "component---src-pages-templates-9-80-work-schedule-js" */),
  "component---src-pages-templates-barista-job-description-js": () => import("./../../../src/pages/templates/barista-job-description.js" /* webpackChunkName: "component---src-pages-templates-barista-job-description-js" */),
  "component---src-pages-templates-food-runner-job-description-js": () => import("./../../../src/pages/templates/food-runner-job-description.js" /* webpackChunkName: "component---src-pages-templates-food-runner-job-description-js" */),
  "component---src-pages-templates-interactive-time-off-request-form-js": () => import("./../../../src/pages/templates/interactive-time-off-request-form.js" /* webpackChunkName: "component---src-pages-templates-interactive-time-off-request-form-js" */),
  "component---src-pages-templates-job-description-template-js": () => import("./../../../src/pages/templates/job-description-template.js" /* webpackChunkName: "component---src-pages-templates-job-description-template-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-templates-restaurant-cleaning-checklist-js": () => import("./../../../src/pages/templates/restaurant-cleaning-checklist.js" /* webpackChunkName: "component---src-pages-templates-restaurant-cleaning-checklist-js" */),
  "component---src-pages-templates-restaurant-closing-checklist-js": () => import("./../../../src/pages/templates/restaurant-closing-checklist.js" /* webpackChunkName: "component---src-pages-templates-restaurant-closing-checklist-js" */),
  "component---src-pages-templates-restaurant-manager-job-description-js": () => import("./../../../src/pages/templates/restaurant-manager-job-description.js" /* webpackChunkName: "component---src-pages-templates-restaurant-manager-job-description-js" */),
  "component---src-pages-templates-restaurant-server-checklist-js": () => import("./../../../src/pages/templates/restaurant-server-checklist.js" /* webpackChunkName: "component---src-pages-templates-restaurant-server-checklist-js" */),
  "component---src-pages-templates-rotating-schedule-template-js": () => import("./../../../src/pages/templates/rotating-schedule-template.js" /* webpackChunkName: "component---src-pages-templates-rotating-schedule-template-js" */),
  "component---src-pages-templates-waiter-job-description-js": () => import("./../../../src/pages/templates/waiter-job-description.js" /* webpackChunkName: "component---src-pages-templates-waiter-job-description-js" */),
  "component---src-pages-why-camelo-js": () => import("./../../../src/pages/why-camelo.js" /* webpackChunkName: "component---src-pages-why-camelo-js" */)
}

